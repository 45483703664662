import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function IntercomGuide() {
  return (
    <Layout
      keywords="Intercom installation guide, Intercom IrisAgent documentation"
      title="Set up IrisAgent AI on your Intercom account"
      description="Documentation on how to integrate IrisAgent with your Intercom account to supercharge your support operations with AI."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/intercom-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Intercom Installation Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Intercom Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>IrisAgent Installation Guide for Intercom</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent delivers proactive customer support with AI-powered alerting, automated resolution, and correlation of support conversations and product issues.

IrisAgent’s AI-powered support solution reduces support conversation resolution time by 45%, improves agent performance, and enhances communications between agents, customers, and internal technical teams to reduce escalations by 60%. It proactively discovers trending issues instead of waiting for customers to report them.
                    <br/>
                    <br/>
                    <h2>
                    Sign up on IrisAgent's Dashboard
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Go to the <a href="https://web.irisagent.com" target="_blank">IrisAgent dashboard</a> and sign in either GSuite or Microsoft SSO.
                    <img
                src="/img/docs-login.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    Click on <b>Connect with Intercom</b>. Give the OAuth permissions and click on Allow.<br/>
                    <img
                src="/img/zd-connect.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    <li>
                    This step is optional. If you want to connect with more platforms, such as Jira, Confluence, PagerDuty, Salesforce CRM, etc., navigate to <b>Manage Integrations</b> on the bottom left and connect with the relevant platforms.
                    </li>
                    </ol>
                    <br/>
                    <h2>
                    Install the IrisAgent app into your Intercom account
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    Navigate to <a href="https://www.Intercom.com/apps/Intercom/irisagent/" target="_blank">IrisAgent's listing</a> on the Intercom Marketplace.
                    </li>
                    <li>
                    Click on <b>Install</b> and follow the next steps.<br/>
                    <img
                src="/img/intercom-ia-app.png"
                style={{
                  width: "99%",
                  padding: "5%",
                }}
                alt="SSO"
              />
                    </li>
                    </ol>

<br/><br/>

The above steps will complete the installation of IrisAgent. Note that it may take a few hours for your Intercom data to be ingested and we will email you once the data ingestion completes. Please <a href="mailto:contact@irisagent.com" target="_blank">email us</a> once these steps are completed, and our team will start setting up customized machine learning models for your account.
                    </div>
                    </div>
                    </section>

</Layout>
  )
}
